import React from 'react';

const SellMain = () => {
    return (
        <div style={{ backgroundColor: 'black', color: 'yellow', padding: '20px', textAlign: 'center', borderRadius: '10px' }}>
            <h1 style={{ marginBottom: '20px' }}>Upload Your Property</h1>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}>
                <button style={{ backgroundColor: 'yellow', color: 'black', padding: '15px 30px', border: 'none', borderRadius: '5px', fontSize: '18px', fontWeight: 'bold', cursor: 'default' }}>
                    Full Apartment Upload
                </button>
                <button style={{ backgroundColor: 'yellow', color: 'black', padding: '15px 30px', border: 'none', borderRadius: '5px', fontSize: '18px', fontWeight: 'bold', cursor: 'default' }}>
                    Room Upload
                </button>
            </div>
            <p style={{ fontSize: '20px', fontWeight: 'bold' }}> For both Send all details at:</p>
            <p style={{ fontSize: '24px', fontWeight: 'bold' }}>+905338417113</p>
        </div>
    );
};

export default SellMain;
