import React from 'react'

function BuyElectronics() {
  return (
    <div>
      buy electronics
    </div>
  )
}

export default BuyElectronics
