import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SSignUpPicture.css'; // Import the CSS file for styles

const SSignUpPicture = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (file) {
      console.log('File ready to be uploaded:', file);
    } else {
      console.log('No file selected');
    }
  };

  const handleNextClick = () => {
    navigate('/sell-main');
  };

  return (
    <div className="upload-profile-container">
      <h2 className="upload-title">Upload Your Profile Picture</h2>
      <form onSubmit={handleSubmit} className="upload-form">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="file-input"
        />
        <button
          type="submit"
          className="upload-button"
        >
          Upload Picture
        </button>
      </form>
      
      <button
        onClick={handleNextClick}
        className="next-button"
      >
        Next
      </button>
      <div className="sign-in-link">
        <a href="/sign-in">Already have an account? Let's sign in</a>
      </div>
    </div>
  );
};

export default SSignUpPicture;
