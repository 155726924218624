import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../../Firebase/Firebase';
import { Card } from 'react-bootstrap';

const SelfUploadSellCar = () => {
  const navigate = useNavigate();

  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedFuelType, setSelectedFuelType] = useState('');
  const [selectedMachineType, setSelectedMachineType] = useState('');
  const [selectedSeats, setSelectedSeats] = useState('');
  const [description, setDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [photos, setPhotos] = useState([]);
  const [previewPhotos, setPreviewPhotos] = useState([]);
  const [inputPrice, setInputPrice] = useState('');
  const [isPriceLocked, setIsPriceLocked] = useState(false);
  const [isPaidStatus, setIsPaidStatus] = useState(false);
  const [showPayPalButton, setShowPayPalButton] = useState(false);

  const makes = ['Mercedes-Benz', 'Nissan', 'BMW', 'Toyota', 'Ford', 'Honda', 'Renault'];
  const models = {
    'Mercedes-Benz': ['C-Class', 'E-Class', 'GLE', 'S-Class', 'G-Class'],
    'Nissan': ['Altima', 'Rogue', 'Maxima', 'Sentra', 'Leaf'],
    'BMW': ['X3', 'M3', 'X5', '5 Series', '3 Series'],
    'Toyota': ['Corolla', 'Camry', 'RAV4', 'Prius', 'Yaris'],
    'Ford': ['Mustang', 'Focus', 'Explorer', 'F-150', 'Edge'],
    'Honda': ['Civic', 'Accord', 'CR-V', 'Fit'],
    'Renault': ['Renault 2005']
  };
  const years = ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015 or Older'];
  const fuelTypes = ['Gasoline', 'Diesel', 'Electric'];
  const machineTypes = ['Manual', 'Automatic'];
  const seatOptions = ['2', '4'];

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    setPhotos([...photos, ...selectedFiles]);

    const uploadedFiles = [];
    for (let file of selectedFiles) {
      const storageRef = ref(storage, `cars/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      uploadedFiles.push(downloadURL);
    }
    setPreviewPhotos(uploadedFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      phoneNumber,
      make: selectedMake,
      model: selectedModel,
      year: selectedYear,
      fuelType: selectedFuelType,
      machineType: selectedMachineType,
      seats: selectedSeats,
      price: inputPrice,
      description,
      photos: previewPhotos,
    };

    try {
      const response = await axios.post('https://cypmark.com/api/endpoints/upload-car-sale', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const setIsPaidToFalseAgain = await axios.patch('https://cypmark.com/api/endpoints/set-isPaid-False');
      console.log(setIsPaidToFalseAgain.data);
      console.log('Upload success', response.data);
      navigate('/car-sale-upload/success');
    } catch (error) {
      console.error('Error:', error);
    }
  };

 
  const addPayPalScript = () => {
    if (window.paypal) {
      renderPayPalButton();
    } else {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;
      script.type = 'text/javascript';
      script.async = true;
      script.onload = renderPayPalButton;
      document.body.appendChild(script);
    }
  };

  const renderPayPalButton = () => {
    if (!inputPrice) return;

    const paymentAmount = (parseFloat(inputPrice) * 0.03).toFixed(2);
    window.paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{ amount: { value: paymentAmount } }],
        });
      },
      onApprove: async (data, actions) => {
        return actions.order.capture().then(async (details) => {
          alert(`Transaction completed by ${details.payer.name.given_name}`);
          try {
            await axios.patch('https://cypmark.com/api/endpoints/paid');
            setIsPaidStatus(true);
            setIsPriceLocked(true);
          } catch (error) {
            console.error('Error updating payment status: ', error);
          }
          window.location.reload();
        });
      },
      onError: (err) => {
        console.error('PayPal Checkout Error: ', err);
        alert('An error occurred during the transaction. Please try again.');
      },
    }).render('#paypal-button-container');
  };

  const handlePayButtonClick = () => {
    setShowPayPalButton(true);
    addPayPalScript();
  };

  useEffect(() => {
    const checkIsPaid = async () => {
      const response = await axios.get('https://cypmark.com/api/endpoints/isPaid');
      setIsPaidStatus(response.data.isPaid);
    };
    checkIsPaid();
  }, []);

  return (
    <div>
      {isPaidStatus ? (
        <>
          <h2 className="text-center" style={{ color: 'yellow' }}>Upload Car for Sale</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Phone Number:</label>
              <input 
                type="text" 
                className="form-control" 
                placeholder="Enter phone number" 
                value={phoneNumber} 
                onChange={(e) => setPhoneNumber(e.target.value)} 
              />
            </div>

            {/* Car Make */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Car Make:</label>
              <div>
                {makes.map(make => (
                  <button
                    key={make}
                    type="button"
                    className={`btn ${selectedMake === make ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => setSelectedMake(make)}
                  >
                    {make}
                  </button>
                ))}
              </div>
            </div>

            {/* Car Model */}
            {selectedMake && (
              <div className="mb-3">
                <label className="form-label" style={{ color: 'purple' }}>Car Model:</label>
                <div>
                  {models[selectedMake].map(model => (
                    <button
                      key={model}
                      type="button"
                      className={`btn ${selectedModel === model ? 'btn-success' : 'btn-warning'} me-2`}
                      onClick={() => setSelectedModel(model)}
                    >
                      {model}
                    </button>
                  ))}
                </div>
              </div>
            )}

            {/* Year of Manufacture */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Year of Manufacture:</label>
              <div>
                {years.map(year => (
                  <button
                    key={year}
                    type="button"
                    className={`btn ${selectedYear === year ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => setSelectedYear(year)}
                  >
                    {year}
                  </button>
                ))}
              </div>
            </div>

            {/* Fuel Type */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Fuel Type:</label>
              <div>
                {fuelTypes.map(fuelType => (
                  <button
                    key={fuelType}
                    type="button"
                    className={`btn ${selectedFuelType === fuelType ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => setSelectedFuelType(fuelType)}
                  >
                    {fuelType}
                  </button>
                ))}
              </div>
            </div>

            {/* Machine Type */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Machine Type:</label>
              <div>
                {machineTypes.map(machineType => (
                  <button
                    key={machineType}
                    type="button"
                    className={`btn ${selectedMachineType === machineType ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => setSelectedMachineType(machineType)}
                  >
                    {machineType}
                  </button>
                ))}
              </div>
            </div>

            {/* Number of Seats */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Number of Seats:</label>
              <div>
                {seatOptions.map(seatOption => (
                  <button
                    key={seatOption}
                    type="button"
                    className={`btn ${selectedSeats === seatOption ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => setSelectedSeats(seatOption)}
                  >
                    {seatOption}
                  </button>
                ))}
              </div>
            </div>

            

            {/* Photos Upload */}
            <div className="mb-3">
              <label className="form-label">Photos of the Car:</label>
              <input type="file" className="form-control" multiple onChange={handleFileUpload} />
            </div>

            {/* Photo Previews */}
            <div className="mb-3">
              {previewPhotos.map((photo, index) => (
                <img key={index} src={photo} alt="Preview" style={{ width: '150px', height: '150px', margin: '10px', objectFit: 'cover' }} />
              ))}
            </div>

            {/* Description */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Description:</label>
              <textarea
                className="form-control"
                rows="4"
                placeholder="Write a description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <button type="submit" className="btn btn-warning w-100">Submit</button>
          </form>
        </>
      ) : (
        <div className="payment-section" style={{ backgroundColor: 'yellow' }}>
          <Card className="payment-card" style={{ backgroundColor: 'yellow' }}>
            <Card.Body>
              <h2 className="payment-title">Unlock Car Rental Upload</h2>
              <p className="payment-description">Make a one-time payment of 9% of your entered price to proceed.</p>
              <div id="paypal-button-container" className="paypal-button"></div>
              <div className="mt-3">
                <label className="form-label" style={{ color: 'purple' }}>Rental Price:</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter rental price"
                  value={inputPrice}
                  onChange={(e) => setInputPrice(e.target.value)}
                  disabled={isPriceLocked}
                />
                <small style={{ color: 'red' }}>Note: Price cannot be changed after payment.</small>
              </div>
              {!showPayPalButton && (
                <button
                  className="btn btn-primary mt-3"
                  onClick={handlePayButtonClick}
                  disabled={!inputPrice || isPriceLocked}
                >
                  Pay
                </button>
              )}
            </Card.Body>
          </Card>
        </div>
      )}
    </div>
  );
};

export default SelfUploadSellCar;
