import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../../Firebase/Firebase';
import { Card } from 'react-bootstrap';

const SelfUploadSellPosition = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appartmentData = useSelector((state) => state.allow.appartment);

  const [selectedCity, setSelectedCity] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [selectedNationalities, setSelectedNationalities] = useState([]);
  const [selectedSex, setSelectedSex] = useState('');
  const [rentalPeriod, setRentalPeriod] = useState('');
  const [deposit, setDeposit] = useState('');
  const [description, setDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [photos, setPhotos] = useState([]);
  const [previewPhotos, setPreviewPhotos] = useState([]);
  const [inputPrice, setInputPrice] = useState('');
  const [isPriceLocked, setIsPriceLocked] = useState(false);
  const [isSharedRoom, setIsSharedRoom] = useState('');
  const [isPaidStatus, setIsPaidStatus] = useState(false);
  const [showPayPalButton, setShowPayPalButton] = useState(false);

  const cities = ['Lefkosa', 'Girne'];
  const sizes = ['3+1', '2+1', '1+1', 'Studio'];
  const people = [1, 2, 3, 4, 5, 6];
  const nationalities = ['Turkish', 'Moroccan', 'Sudanese', 'Yemenian', 'Nigerian', 'Kongo', 'Russian', 'Syrian', 'Egyptian', 'any'];
  const sexes = ['Male', 'Female', 'any'];
  const rentalPeriods = ['3 months', '6 months'];
  const deposits = ['1 deposit', '2 deposits'];

  const toggleSelection = (item, setSelected, selected) => {
    if (selected.includes(item)) {
      setSelected(selected.filter((i) => i !== item));
    } else {
      setSelected([...selected, item]);
    }
  };

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    setPhotos([...photos, ...selectedFiles]);

    const uploadedFiles = [];
    for (let file of selectedFiles) {
      const storageRef = ref(storage, `apartments/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      uploadedFiles.push(downloadURL);
    }
    setPreviewPhotos(uploadedFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      phoneNumber,
      city: selectedCity,
      size: selectedSize,
      price: inputPrice,
      numberOfPersons: selectedPeople.join(','),
      nationalities: selectedNationalities.join(','),
      sex: selectedSex,
      rentalPeriod,
      depositAmount: deposit,
      description,
      photos: previewPhotos,
      sharedRoom: isSharedRoom,
    };

    try {
      const response = await axios.post('https://cypmark.com/api/endpoints/upload-sell-position', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const setIsPaidToFalseAgain = await axios.patch('https://cypmark.com/api/endpoints/set-isPaid-False');
      console.log(setIsPaidToFalseAgain.data);
      console.log('Upload success', response.data);
      navigate('/self-upload-rent-appartment/deadend');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const addPayPalScript = () => {
    if (window.paypal) {
      renderPayPalButton();
    } else {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;
      script.type = 'text/javascript';
      script.async = true;
      script.onload = renderPayPalButton;
      document.body.appendChild(script);
    }
  };

  const renderPayPalButton = () => {
    if (!inputPrice) return;

    const paymentAmount = (parseFloat(inputPrice) * 0.20).toFixed(2);
    window.paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{ amount: { value: paymentAmount } }],
        });
      },
      onApprove: async (data, actions) => {
        return actions.order.capture().then(async (details) => {
          alert(`Transaction completed by ${details.payer.name.given_name}`);
          try {
            await axios.patch('https://cypmark.com/api/endpoints/paid');
            setIsPaidStatus(true);
            setIsPriceLocked(true);
          } catch (error) {
            console.error('Error updating payment status: ', error);
          }
          window.location.reload();
        });
      },
      onError: (err) => {
        console.error('PayPal Checkout Error: ', err);
        alert('An error occurred during the transaction. Please try again.');
      },
    }).render('#paypal-button-container');
  };

  const handlePayButtonClick = () => {
    setShowPayPalButton(true);
    addPayPalScript();
  };

  useEffect(() => {
    const checkIsPaid = async () => {
      const response = await axios.get('https://cypmark.com/api/endpoints/isPaid');
      setIsPaidStatus(response.data.isPaid);
    };
    checkIsPaid();
  }, []);
  return (
    <div>
      {isPaidStatus ? (
        <>
          <h2 className="text-center" style={{ color: 'yellow' }}>Upload Position for Sale</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Phone Number:</label>
              <input 
                type="text" 
                className="form-control" 
                placeholder="Enter phone number" 
                value={phoneNumber} 
                onChange={(e) => setPhoneNumber(e.target.value)} 
              />
            </div>

            {/* City Selection */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>City:</label>
              <div>
                {cities.map(city => (
                  <button
                    key={city}
                    type="button"
                    className={`btn ${selectedCity === city ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => setSelectedCity(city)}
                  >
                    {city}
                  </button>
                ))}
              </div>
            </div>

            {/* Apartment Size */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Size:</label>
              <div>
                {sizes.map(size => (
                  <button
                    key={size}
                    type="button"
                    className={`btn ${selectedSize === size ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => setSelectedSize(size)}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>

            {/* Number of People */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Number of People:</label>
              <div>
                {people.map(person => (
                  <button
                    key={person}
                    type="button"
                    className={`btn ${selectedPeople.includes(person) ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => toggleSelection(person, setSelectedPeople, selectedPeople)}
                  >
                    {person}
                  </button>
                ))}
              </div>
            </div>

            {/* Shared Room */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Shared Room:</label>
              <div>
                <button
                  type="button"
                  className={`btn ${isSharedRoom === 'Yes' ? 'btn-success' : 'btn-warning'} me-2`}
                  onClick={() => setIsSharedRoom('Yes')}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`btn ${isSharedRoom === 'No' ? 'btn-success' : 'btn-warning'} me-2`}
                  onClick={() => setIsSharedRoom('No')}
                >
                  No
                </button>
              </div>
            </div>

            {/* Nationalities */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Nationalities:</label>
              <div>
                {nationalities.map(nationality => (
                  <button
                    key={nationality}
                    type="button"
                    className={`btn ${selectedNationalities.includes(nationality) ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => toggleSelection(nationality, setSelectedNationalities, selectedNationalities)}
                  >
                    {nationality}
                  </button>
                ))}
              </div>
            </div>

            {/* Rental Period */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Rental Period:</label>
              <div>
                {rentalPeriods.map(period => (
                  <button
                    key={period}
                    type="button"
                    className={`btn ${rentalPeriod === period ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => setRentalPeriod(period)}
                  >
                    {period}
                  </button>
                ))}
              </div>
            </div>

            {/* Deposit Amount */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Deposit Amount:</label>
              <div>
                {deposits.map(depositAmount => (
                  <button
                    key={depositAmount}
                    type="button"
                    className={`btn ${deposit === depositAmount ? 'btn-success' : 'btn-warning'} me-2`}
                    onClick={() => setDeposit(depositAmount)}
                  >
                    {depositAmount}
                  </button>
                ))}
              </div>
            </div>

            {/* Description */}
            <div className="mb-3">
              <label className="form-label" style={{ color: 'purple' }}>Description:</label>
              <textarea
                className="form-control"
                rows="4"
                placeholder="Write a description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            {/* Photo Upload */}
            <div className="mb-3">
              <label className="form-label">Photos:</label>
              <input type="file" className="form-control" multiple onChange={handleFileUpload} />
            </div>

            {/* Photo Previews */}
            <div className="mb-3">
              {previewPhotos.map((photo, index) => (
                <img key={index} src={photo} alt="Preview" style={{ width: '150px', height: '150px', margin: '10px', objectFit: 'cover' }} />
              ))}
            </div>

            <button type="submit" className="btn btn-warning w-100">Submit</button>
          </form>
        </>
      ) : (
        <div className="payment-section" style={{ backgroundColor: 'yellow' }}>
          <Card className="payment-card" style={{ backgroundColor: 'yellow' }}>
            <Card.Body>
              <h2 className="payment-title">Unlock Car Rental Upload</h2>
              <p className="payment-description">Make a one-time payment of 9% of your entered price to proceed.</p>
              <div id="paypal-button-container" className="paypal-button"></div>
              <div className="mt-3">
                <label className="form-label" style={{ color: 'purple' }}>Rental Price:</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter rental price"
                  value={inputPrice}
                  onChange={(e) => setInputPrice(e.target.value)}
                  disabled={isPriceLocked}
                />
                <small style={{ color: 'red' }}>Note: Price cannot be changed after payment.</small>
              </div>
              {!showPayPalButton && (
                <button
                  className="btn btn-primary mt-3"
                  onClick={handlePayButtonClick}
                  disabled={!inputPrice || isPriceLocked}
                >
                  Pay
                </button>
              )}
            </Card.Body>
          </Card>
        </div>
      )}
    </div>
  );
};

export default SelfUploadSellPosition;
