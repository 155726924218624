import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SellMainHome.css'; // Custom CSS for hover and styles
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'react-bootstrap';

function SellMainHome() {
  const navigate = useNavigate();
  const [permission, setPermission] = useState(false);

  let token;
  const tokenString = localStorage.getItem('providerToken');
  const tokenObject = JSON.parse(tokenString);
  token = tokenObject?.token || tokenObject;

  const routeMap = {
    'Rent an Apartment': '/rent-apartment',
    'Rent Your Car': '/rent-car',
    'Sell Your Car': '/sell-car',
  };

  useEffect(() => {
    if (token) {
      const fetchPermission = async () => {
        try {
          const response = await axios.get('https://cypmark.com/api/endpoints/verifyProvider', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setPermission(response.data.permission);
        } catch (err) {
          console.error('Error fetching permission:', err);
        }
      };
      fetchPermission();
    } else {
      console.error('No valid token found');
    }
  }, [token]);

  return (
    <div className="container-fluid main-container">
      {permission ? (
        <div className="row justify-content-center text-center g-4">

            <div className="col-lg-3 col-md-6 mb-3" >
              <button className="btn btn-custom" onClick={() => navigate('/rent-your-apartment')}>
                
Rent your appartment

              </button>
            </div>

            <div className="col-lg-3 col-md-6 mb-3" >
              <button className="btn btn-custom" onClick={() => navigate('/rent-your-car')}>
                
Rent your car

              </button>
            </div>


            <div className="col-lg-3 col-md-6 mb-3" >
              <button className="btn btn-custom" onClick={() => navigate('/sell-your-car')}>
                
Sell your car

              </button>
            </div>

            <div className="col-lg-3 col-md-6 mb-3" >
              <button className="btn btn-custom" onClick={() => navigate('/sell-your-position')}>
                
Sell your position 

              </button>
            </div>
        </div>
      ) : (
        <div className="text-center mt-5">
          <Button variant="primary" size="lg" onClick={() => navigate('/auth')}>
            Sign Up/In
          </Button>
        </div>
      )}
    </div>
  );
}

export default SellMainHome;
