import React from 'react'

function SiteUploadRentCar() {
  return (
    <div>
      site upload rent car
    </div>
  )
}

export default SiteUploadRentCar
