import React from 'react';

import { useNavigate } from 'react-router-dom';

function BuyMain() {
    const navigate = useNavigate()
  return (
    <div className="container" style={{ background: 'linear-gradient(to bottom, yellow, purple)', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <button
        style={{
          backgroundColor: 'yellow',
          color: 'black',
          padding: '20px 40px',
          fontSize: '1.5rem',
          border: 'none',
          borderRadius: '10px',
          margin: '20px',
          cursor: 'pointer',
          transition: 'background-color 0.5s ease, color 0.5s ease',
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = 'purple')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = 'yellow')}
        onClick={() => navigate('/rent-apart')}
      >
        Rent Full Apartment for Full Team
      </button>

      <button
        style={{
          backgroundColor: 'yellow',
          color: 'black',
          padding: '20px 40px',
          fontSize: '1.5rem',
          border: 'none',
          borderRadius: '10px',
          margin: '20px',
          cursor: 'pointer',
          transition: 'background-color 0.5s ease, color 0.5s ease',
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = 'purple')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = 'yellow')}
        onClick={() => navigate('/buy-position')}
      >
        Find Position with Others
      </button>
    </div>
  );
}

export default BuyMain;